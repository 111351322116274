:root {
  --black: #1f1f1f;
  --oxford-blue: #082249;
  --lime: #bfff00;
  --dim-gray: #666666;
  --navbar-height: 81px;
}

.ubuntu-light {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ubuntu-regular {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ubuntu-medium {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ubuntu-bold {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.ubuntu-light-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.ubuntu-regular-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.ubuntu-medium-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.ubuntu-bold-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .page-header {
    margin-bottom: 5rem;
  }

  /* overwriting the default header styles */
  & h1 {
    font-size: 3.5rem;
  }
  & h2 {
    font-size: 2.5rem;
  }
  & h3 {
    font-size: 2.25rem;
  }
  & h4 {
    font-size: 2rem;
  }
  & h5 {
    font-size: 1.5rem;
  }
  & h6 {
    font-size: 1.25rem;
  }

  /* default classes with pre-defined font-sizes */
  & .h1 {
    font-size: 3.5rem;
  }
  & .h2 {
    font-size: 2.5rem;
  }
  & .h3 {
    font-size: 2.25rem;
  }
  & .h4 {
    font-size: 2rem;
  }
  & .h5 {
    font-size: 1.5rem;
  }
  & .h6 {
    font-size: 1.25rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-control {
  border: 0;
  border-bottom: 0.5px solid #000;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
}

.form-control:focus {
  box-shadow: none;
  outline: none !important;
  border-color: #000;
}

.form-control.is-invalid:focus {
  box-shadow: none;
  outline: none !important;
  border-color: var(--bs-form-invalid-border-color);
}

.spinner-border {
  color: var(--oxford-blue);
}

@media only screen and (max-width: 950px) {
  body {
    .page-header {
      margin-bottom: 3rem;
    }
  }
}

.header {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100% !important;
  background: #000 !important;
  padding-left: 30px;
  padding-right: 30px;
}
.headerLogo img {
  height: 55px;
}
.navLink {
  color: rgb(234, 224, 224) !important;
  @media only screen and (min-width: 768px) {
    padding-left: 30px !important;
  }
}
.navbarToggler {
  padding-top: 0.4rem;
  border: none;
}

/* Styling for the on click hamburger icon animation */
.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.hamburger {
  margin: 0 auto;
  width: 25px;
  height: 20px;
  position: relative;
}
.hamburger .bar {
  padding: 0;
  width: 25px;
  height: 3px;
  background-color: #9e9e9e;
  display: block;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  position: absolute;
  transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
}
.hamburger .bar1 {
  top: 0;
}
.hamburger .bar2 {
  width: 1px;
  transform: rotate(90deg);
  left: 11.5px;
}
.hamburger .bar2,
.hamburger .bar3 {
  top: 8.32px;
}
.hamburger .bar3 {
  right: 0;
  width: 1px;
  left: 11.5px;
}
.hamburger .bar4 {
  bottom: 0;
  top: 8.32px;
}
.hamburger .bar5 {
  bottom: 0px;
}
.checkbox:checked + label > .hamburger > .bar1 {
  top: 8.32px;
  background-color: transparent;
}
.checkbox:checked + label > .hamburger > .bar2 {
  left: 0px;
  width: 25px;
  transform: rotate(45deg);
}
.checkbox:checked + label > .hamburger > .bar3 {
  left: 0;
  width: 25px;
  transform: rotate(-45deg);
}
.checkbox:checked + label > .hamburger > .bar4 {
  background-color: transparent;
}
.checkbox:checked + label > .hamburger > .bar5 {
  bottom: 11.5px;
  background-color: transparent;
}
